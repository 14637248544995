import React from "react"

export default function WhoWeAre() {
  return (
    <>
      {/* <section id="about" className="clearfix">
        <header className="section-header">
              <h3>Who We Are</h3>
              </header>
        <div id="values_banner">
          <div className="container">
          
            <div
              className="values_banner-info"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <h2>Our Vision</h2>
              <p className="banner-text">
                Need Text
              </p>
              <h2>Our Approach</h2>
              <p className="banner-text">
                Need Text
              </p>
            </div>
          </div>
        </div>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 mb-12 col-sm-4"></div>
          </div>

          <div
            className="banner-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          ></div>
        </div>
      </section> */}
      <section id="about">
        <div className="container" data-aos="fade-up">
          <div className="row row-eq-height justify-content-center">
            <div className="col-lg-8 mb-12 col-sm-4">
              <p className="bg-values img-fluid"></p>
            </div>
          </div>
        <header className="section-header">
          <h3>Who We Are</h3>
        </header>
        <section id="our-section">
          <div className="row row-eq-height justify-content-center">
            <div className="col-lg-4 mb-4">
              <div className="card" data-aos="zoom-in" data-aos-delay="100">
                <i className="bi bi-lightbulb icon-center" style={{color:"red"}}></i>
                <div className="card-body">
                  <h5 className="card-title">Tech Innovator</h5>
                  <p className="card-text">
                    Systemize, accelerate or incubate your business with our Creative Technology.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card" data-aos="zoom-in" data-aos-delay="200">
                <i className="bi bi-graph-up icon-center" style={{color:"red"}}></i>
                <div className="card-body">
                  <h5 className="card-title">Business Expansion, Integrate & Optimization </h5>
                  <p className="card-text">
                    Implement your desired vision with Our Strategic Thinking, Domain knowledge, and partner ecosystem.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card" data-aos="zoom-in" data-aos-delay="300">
                <i class="bi bi-building icon-center" style={{color:"red"}}></i>
                <div className="card-body">
                  <h5 className="card-title">Business Incubation</h5>
                  <p className="card-text">
                    Establish or scale your business within & beyond North America with our skilful marketing, scalable systems, smart money, and expert talent
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    </>
  )
}
